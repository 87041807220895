<template>
  <div style="padding:20px">
    <div class="row">
      <div class="col-5">
        <label class="form-label float-right"><em class="icon ni ni-users"/> Team Name:</label>
      </div>
      <div class="col-5">
        <a-input v-model="team.teamName" placeholder="Team Name" style="width:100%" />
      </div>
    </div>
    <div class="row">
      <div class="col-5">
        <label class="form-label float-right"><em class="icon ni ni-edit"/> Team Color:</label>
      </div>
      <div class="col-5">
        <a-popover placement="leftBottom" title="Style your answer" trigger="click" v-model="showColorPicker">
          <template slot="content">
            <!-- <compact-picker @input="updateTeamColor()" v-model="colors" ></compact-picker> -->
            <vueswatches inline swatchSize="30" v-model="team.color" />
          </template>
          <a @click="toggleShowAnswerColorPicker()" slot="content">Close</a>
          <avatar style="margin:2px;" class="float-left mr-2" :size="30" :backgroundColor="team.color" :rounded="true" :username="team.teamName" />
        </a-popover>
      </div>
    </div>
    <a-button class="btn btn-success btn-sm float-right" :disabled="!team.teamName" @click="addTeam()">
        <em class="icon ni ni-save" style="margin: 2px;"/> Add Team
    </a-button>
  </div>
</template>

<script>
// import store from '@/store'
export default {
  components: {
  },
  data: function () {
    return {
      showColorPicker: false,
      displayMe: true,
      roles: [],
      labelCol: { span: 8 },
      wrapperCol: { span: 16 },
      team: { teamName: '', color: '#232943' },
      colors: {
        hex: '#194d33e6',
        hsl: {
          h: 150,
          s: 0.5,
          l: 0.2,
          a: 0.9,
        },
        hsv: {
          h: 150,
          s: 0.66,
          v: 0.30,
          a: 0.9,
        },
        rgba: {
          r: 159,
          g: 96,
          b: 43,
          a: 0.9,
        },
        a: 0.9,
      },
    }
  },
  name: 'adTeam',
  created() {
    this.resetTeam()
  },
  beforeDestroy() {
  },
  methods: {
    resetTeam() {
      this.team = { teamName: '', color: '#232943' }
    },
    toggleShowAnswerColorPicker () {
      if (this.showColorPicker === false) {
        this.showColorPicker = true
      } else {
        this.showColorPicker = false
      }
    },
    updateTeamColor () {
      this.team.color = this.colors.hex
    },
    emailChanged () {
      if (this.team.email === null || this.team.email === '') {
        this.team.sendTeamWelcomeEmail = false
      }
    },
    addTeam: function () {
      this.$http.post('/teams/Add_Team/', this.team)
        .then(() => {
          this.resetTeam()
          this.$emit('teamAdded')
        })
        .catch(() => {
          this.$message.error('There has been an error')
        })
    },
  },
}
</script>

<style scoped>
.form-label{ 
  padding-top: 6px;
}
</style>
