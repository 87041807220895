<template>
    <div class="nk-content-body">
      <div class="nk-block">
        <div class="p-2">
          <div class="row">
            <div class="col-12">
              <!-- <a-button class="btn btn-primary float-right" style="margin-left:10px;" @click="changeView()"><em class="icon ni ni-view-group-wd"/></a-button> -->
              <!-- <a-button class="btn btn-primary float-right" style="margin-left:10px;" @click="changeView()"><em class="icon ni ni-list-index"/></a-button> -->
              <a-button v-on:click="openAddTeam()" class="btn btn-dark float-right"><em class="icon ni ni-plus" style="margin-bottom: 2px;"></em> Add Team</a-button>
              <a-button v-if="getSelectedUserCount > 0" v-on:click="deleteSelectedTeamUsers()" class="btn btn-danger mr-2 float-right" style="margin-right:10px;"><em class="icon ni ni-trash-fill"></em> Delete Selected</a-button>
              <h3 class="nk-block-title page-title">Teams Manager</h3>
            </div>
          </div>
          <div class="row" v-if="view === 'list'">
            <div class="col-lg-10">
              <div class="row" v-for="(team, index) in teams" v-bind:key="team.id">
                <div class="col-lg-12">
                  <div class="card card-shadow card-inner">
                    <div class="mb-4">
                      <a-dropdown class="pt-1 float-right" :placement="'bottomRight'">
                        <a><em class="icon ni ni-more-h" style="fontSize: 23px"></em></a>
                        <a-menu slot="overlay">
                          <a-menu-item style="color: red;" @click="deleteTeam(team)"><em class="icon ni ni-trash-alt"/> Delete</a-menu-item>
                          <a-menu-item style="color: black;" @click="expireTeam(team)"><em class="icon ni ni-cross-circle"/> Expire</a-menu-item>
                        </a-menu>
                      </a-dropdown>
                      <avatar class="float-left mr-2" :size="30" style="margin:2px;" :backgroundColor="team.color" :rounded="true" :username="team.teamName" />
                      <h5 class="pt-1" style="padding-bottom:20px; border-bottom: 1px solid lightgrey;">
                        <span class="mr-3 ">{{team.teamName}}</span>
                      </h5>
                    </div>
                    <div class="mb-2 teamMgrContainer text-center">
                      <draggable
                        :id="team.id"
                        class="dragArea teammgmt"
                        :list="team.teamUsers"
                        group="people"
                        :disabled="existsInTeam(index, draggingUserId)"
                        @add="startAddToTeam"
                      >
                      <!-- <span v-if="!team.teamUser"> + Drag an Auditable or User to add them to this team</span> -->
                        <div class="row">
                          <div class="col-12">
                            
                          </div>
                        </div>
                        <div v-on:click="toggleSelectUser(user)"  v-for="user in team.teamUsers" :key="user.id" class="auditableContainer">
                          <div class="user-card mb-1 tmUserBox" >
                            <div class="tmdeleteBox" v-bind:class="{'selected' : user.selected}" style=" margin-left:-4px;">
                              <div style="color:#fff; margin-top:7px; font-size:20px;">
                                <em class="icon ni ni-trash-fill" style=""></em>
                              </div>
                            </div>
                            <div class="">
                                <avatar class="float-right" :size="30" style="margin:2px;" :backgroundColor="user.color" :rounded="true" :username="user.name" />
                            </div>
                            <div class="user-name">{{user.name}}</div>
                          </div>
                          <!-- <div class="auditableAvatar">
                            <avatar class="float-right" :size="30" style="margin:2px;" :backgroundColor="user.color" :rounded="true" :username="user.name" />
                          </div>
                          <div class="auditableContent">
                            {{user.name}}
                            <div class="auditableInfo">
                              <span><i class="fa fa-user  mr-1"></i> User</span>
                            </div>
                          </div> -->
                        </div>
                      </draggable>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
            <div class="col-2" >
              <div class="card card-shadow" >
                <div v-if="!users || users.length <= 0">
                  No Users available why not add some? 
                  <a-button class="btn btn-dark float-right">
                    <em class="icon ni ni-edit" style="margin-right:4px; margin-bottom: 2px;"/>
                    <router-link :to="{name: 'users'}" style="color: white;"> Manage Users</router-link>
                  </a-button>
                </div>
                <div class="row">
                  <div class="col-12">
                    <div class="card-inner" style="padding-top:15px;">
                      <div class="row" style="border-bottom: 1px solid lightgrey;">
                        <div class="col-12">
                          <a-button class="btn btn-dark float-right">
                            <em class="icon ni ni-edit" style="margin-right:4px; margin-bottom: 2px;"/>
                            <router-link :to="{name: 'users'}" style="color: white;"> Manage Users</router-link>
                          </a-button>
                          <h5 class="pt-1"><em class="icon ni ni-user"></em> User List</h5>
                          <div style="padding-top:10px;">
                            <a-input v-model="searchFilter" placeholder="Search Users">
                              <em class="icon ni ni-search" slot="prefix"></em>
                            </a-input>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12">
                          <draggable
                            class="dragArea"
                            :list="filteredUsers"
                            :clone="deepClone"
                            :group="{ name: 'people', pull: 'clone', put: false }"
                            @start="startDragUser"
                            >
                              <div v-for="user in filteredUsers" v-bind:key="user.id" class="auditableContainer">
                                <div class="user-card mb-1" style="cursor:pointer;">
                                    <div class="">
                                        <avatar class="float-right" :size="30" style="margin:2px;" :backgroundColor="user.color" :rounded="true" :username="user.name" />
                                    </div>
                                    <div class="user-name">{{user.name}}</div>
                                </div>
                              </div>
                          </draggable>
                        </div>  
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--- table view --->
          <!-- <div class="row" v-if="view === 'table'">
            <div class="col-12">
              <div class="">
                <div class="" style="position:relative;">
                  <vue-good-table
                    styleClass="vgt-table condensed"
                    :columns="columns"
                    :rows="teams"
                    :search-options="{ enabled: true, trigger: 'enter', placeholder: 'Search My Teams'}"
                    :sort-options="{ enabled: true,}"
                    :pagination-options="{
                      enabled: true,
                      mode: 'records',
                      perPage: 50,
                      position: 'bottom',
                      perPageDropdown: [50,100,150],
                      dropdownAllowAll: false,
                      setCurrentPage: 1,
                      nextLabel: 'next',
                      prevLabel: 'prev',
                      rowsPerPageLabel: 'Rows per page',
                      ofLabel: 'of',
                      pageLabel: 'page', // for 'pages' mode
                      allLabel: 'All',
                    }"
                    @on-selected-rows-change="toggleTeamSelected"
                  >
                    <div slot="emptystate">There are no Teams feel free to add one.</div>
                    <template slot="table-row" slot-scope="props">
                      <div v-if="props.column.field === 'color'">
                          <avatar  :src="props.row.avatarURI"  style="margin:2px;" :size="30" :backgroundColor="props.row.color" :rounded="true" :username="props.row.teamName" />
                      </div>
                      <div v-else-if="props.column.field === 'after'">
                        <a-popconfirm
                          title="Are you sure you want to delete the selected team?"
                          @confirm="deleteTeam(props.row)"
                          @cancel="cancelDeleteSelectedTeam"
                          okText="Yes"
                          cancelText="No"
                        >
                          <button class="btn btn-xs btn-danger"><i class="fa fa-trash mr-1"></i> Delete</button>
                        </a-popconfirm>
                      </div>
                      <div v-else>
                          {{ props.formattedRow[props.column.field] }}
                      </div>
                    </template>
                  </vue-good-table>
                </div>
              </div>
            </div>
          </div> -->
      </div>
    </div>
    <!-- drawers -->
      <!-- add team  -->
      <a-drawer
        title="Add Team"
        :width="650"
        placement="right"
        :closable="true"
        @close="closeAddTeam()"
        :visible="showTeam"
      >
        <team v-on:teamAdded="teamAdded"></team>
      </a-drawer>
        <!-- end add team  -->
      <!-- import teams  -->
      <a-drawer
        title="Import Teams"
        placement="right"
        :closable="true"
        :visible="showImportTeams"
      >
        <p>ImportTeams</p>
        <p>Some contents...</p>
        <p>Some contents...</p>
      </a-drawer>
      <!-- end import teams  -->
    <!-- end drawers -->
  </div>
</template>
<script>
import team from '@/components/crm/teams/team'
import draggable from 'vuedraggable'
export default {
  components: {
    team,
    draggable,
  },
  computed: {
    // a computed getter
    getSelectedUserCount () {
      // `this` points to the vm instance
      let countski = 0
      if (this.teams.length > 0) {
        this.teams.forEach(team => {
          team.teamUsers.forEach(user => {
            if (user.selected) {
              countski += 1
            }
          })
        })
      }
      return countski
    },
    filteredUsers: function() {
        if (this.searchFilter != '') {
            return this.users.filter((f) => {  return f.name.toLowerCase().match(this.searchFilter.toLowerCase()) })
        }
        return this.users 
    },
  },
  data: function () {
    return {
      searchFilter: '',
      draggingUserId: null,
      auditables: [], // [{ id: 'zzzzz', name: 'Auditable One' }, { id: 'xxxxx', name: 'Auditable Two' }, { id: 'mmmmmmm', name: 'Auditable Three' }],
      users: [],
      showTeam: false,
      showImportTeams: false,
      selectedTeams: [],
      teams: [],
      columns: [
        { label: '', field: 'color', width: '40px', thClass: 'text-center', tdClass: 'text-center' },
        { label: 'Team', field: 'teamName' },
        { label: '', width: '140px', field: 'after', tdClass: 'text-center', thClass: 'text-center' },
      ],
      view: 'list',
    }
  },
  methods: {
    changeView(){
      if ( this.view === 'list') {
        this.view = 'table'
      } else {
        this.view = 'list'
      }
    },
    deepClone(el) {
      return JSON.parse(JSON.stringify(el))
    },
    fillEmptyTeamIds () {
      this.teams.forEach(team => {
        team.teamUsers.forEach(gi => {
          if (gi.teamId === null) {
            gi.teamId = team.id
          }
        })
      })
    },
    removeDeletedTeamUsers () {
      // cheating...
      this.getComponent()
      // this.teams.forEach(team => {
      //   teamUsers.forEach(user => {
      //     if (user.teamId === team.id) {
      //       // team.teamUsers = team.teamUsers.filter(i => i.id !== user.id)
      //     }
      //   })
      // })
    },
    deleteSelectedTeamUsers () {
      let teamUsers = []
      this.teams.forEach(team => {
        team.teamUsers.forEach(tu => {
          if (tu.selected) {
            teamUsers.push(tu)
          }
        })
      })
      this.$http.post('/Teams/delete_TeamUsers/', teamUsers)
        .then(() => {
          this.removeDeletedTeamUsers(teamUsers)
          this.$message.success('Users Deleted')
        })
        .catch(() => {
          this.$message.error('There has been an error')
        })
    },
    cancel() {
      this.$message.error('Cancelled')
    },
    toggleSelectUser (user) {
      if (user.selected) {
        user.selected = false
      } else {
        user.selected = true
      }
    },
    existsInTeam(teamIndex, id) {
      let existsInArray = false
      if (this.draggingUserId) {
        this.teams[teamIndex].teamUsers.forEach(element => {
          if (element.id === id) {
            existsInArray = true
          }
        })
      }
      return existsInArray
    },
    startDragUser (evt) {
      this.draggingUserId = evt.item._underlying_vm_.id
    },
    startAddToTeam(evt) {
      let teamId = evt.to.id
      let userId = evt.item._underlying_vm_.id
      let vars = { teamId: teamId, userId: userId }
      this.$http.post('/teams/addToTeam/', vars)
        .then(() => {
          this.fillEmptyTeamIds()
        })
        .catch(() => {
          this.$message.error('There has been an error')
        })
    },
    cancelDeleteSelectedTeam () {
      this.$message.error('Delete Cancelled')
    },
    deleteTeam (team) {
      this.$http.post('/Teams/Delete_Team/', team)
        .then(() => {
          this.removeDeletedTeam(team)
          this.$message.success('Team Deleted')
        })
        .catch(() => {
          this.$message.error('There has been an error')
        })
    },
    expireTeam (team) {
      this.$http.post('/Teams/Expire_Team/', team)
        .then(() => {
          this.removeDeletedTeam(team)
          this.$message.success('Team Expired')
        })
        .catch(() => {
          this.$message.error('There has been an error')
        })
    },
    removeDeletedTeam (team) {
      this.teams.forEach(t => {
        if (t.id === team.id){
          this.teams.splice(this.teams.indexOf(t), 1)
        }
      }); 
    },
    toggleTeamSelected (selectedRows) {
      this.selectedTeams = selectedRows.selectedRows
    },
    getComponent () {
      this.$http.get('/teams/get_Teams')
        .then((response) => {
          this.teams = response.data.teams
          this.users = response.data.users
          this.auditables = response.data.auditables
        })
        .catch(() => {
          this.$message.error('There has been an error')
        })
    },
    teamAdded() {
      this.getComponent()
      this.closeAddTeam()
    },
    openImportTeams () {
      this.showImportTeams = true
      this.closeaddTeam()
    },
    closeImportTeams () {
      this.showImportTeams = false
    },
    openAddTeam () {
      this.showTeam = true
      this.closeImportTeams()
    },
    closeAddTeam () {
      this.showTeam = false
    },
    getView (){ this.$http.get('/ViewAccess/teams')
        .then(() => { 
        })
        .catch(() => { 
        })},
  },
  created () {
    this.getView()
    this.getComponent()
  },
}
</script>
<style>
.tmUserBox{
    margin-right:5px;
    cursor: pointer;
    float: left;
    padding: 5px;
    box-shadow: 0px 3px 10px -1px rgba(0,0,0,0.35);
    position:relative;
}

.tmdeleteBox {
    position: absolute;
    opacity: 0;
    z-index: 10;
}
.tmdeleteBox.selected {
    width: 100%;
    height: 100%;
    opacity: 0.6;
    
    background-color: rgb(182, 11, 11);
    box-shadow: 0px 3px 10px -1px rgba(0,0,0,0.35);
}
.teamMgrContainer  {
  height:100%
}

</style>

<!-- old code -->
<!-- <div class="row">
        <div class="col-md-9">
            <div v-if="!teams || teams.length <= 0">There are no Teams feel free to <a class="text-primary" v-on:click="openAddTeam()">add one.</a></div>
                <div v-if="teams.length > 0">
                    <div v-for="(team, index) in teams" v-bind:key="team.id">
                        <div class="row">
                            <div class="col-md-12">
                              <div class="mb-4">
                                  <div class="float-right">
                                      <a-dropdown>
                                        <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                                        <a-icon type="ellipsis" />
                                        </a>
                                        <a-menu slot="overlay">
                                          <a-menu-item>
                                              <a-popconfirm
                                                title="Are you sure you want to delete the selected team?"
                                                ok-text="Yes"
                                                cancel-text="No"
                                                @confirm="deleteTeam(team)"
                                                @cancel="cancel"
                                              >
                                                <a>Delete Team</a>
                                              </a-popconfirm>
                                          </a-menu-item>
                                          <a-menu-item>
                                              <a-popconfirm
                                                title="Are you sure expire this team?"
                                                ok-text="Yes"
                                                cancel-text="No"
                                                @confirm="expireTeam(team)"
                                                @cancel="cancel"
                                              >
                                                <a >Expire Team</a>
                                              </a-popconfirm>
                                          </a-menu-item>
                                        </a-menu>
                                      </a-dropdown>
                                  </div>
                                  <avatar class="float-left mr-2" :size="30" style="margin:2px;" :backgroundColor="team.color" :rounded="true" :username="team.teamName" />
                                  <h5 class=" pt-1">
                                    <span class="mr-3 ">{{team.teamName}}</span>
                                  </h5>
                                </div>
                                <div class="mb-2 teamMgrContainer text-center text-muted" style="">
                                  <draggable
                                    :id="team.id"
                                    class="dragArea teammgmt"
                                    :list="team.teamUsers"
                                    group="people"
                                    :disabled="existsInTeam(index, draggingUserId)"
                                    @add="startAddToTeam"
                                  >
                                <span v-if="!team.teamUser"> + Drag an Auditable or User to add them to this team</span>
                                    <div class="row">
                                      <div class="col-12">
                                        
                                      </div>
                                    </div>
                                    <div v-on:click="toggleSelectUser(user)"  v-for="user in team.teamUsers" :key="user.id" class="auditableContainer">
                                      <div class="user-card mb-1 tmUserBox" >
                                        <div class="tmdeleteBox" v-bind:class="{'selected' : user.selected}" style=" margin-left:-4px;">
                                          <div style="color:#fff; margin-top:7px; font-size:20px;">
                                            <em class="icon ni ni-trash-fill" style=""></em>
                                          </div>
                                        </div>
                                        <div class="">
                                            <avatar class="float-right" :size="30" style="margin:2px;" :backgroundColor="user.color" :rounded="true" :username="user.name" />
                                        </div>
                                        <div class="user-name">{{user.name}}</div>
                                      </div>
                                      <div class="auditableAvatar">
                                        <avatar class="float-right" :size="30" style="margin:2px;" :backgroundColor="user.color" :rounded="true" :username="user.name" />
                                      </div>
                                      <div class="auditableContent">
                                        {{user.name}}
                                        <div class="auditableInfo">
                                          <span><i class="fa fa-user  mr-1"></i> User</span>
                                        </div>
                                      </div>
                                    </div>
                                  </draggable>
                                </div>
                            </div>
                          </div>
                        </div>
                  </div>
          </div>
  <div class="col-md-3">
    <a-tabs default-active-key="1">
      <div slot="tabBarExtraContent" >
        <router-link :to="{name: 'users'}" class="text-primary"> Add/Manage Users.</router-link>
      </div>
      <a-tab-pane key="1" tab="Users" force-render>
        <div v-if="!users || users.length <= 0">
          No Users available why not add some? <router-link :to="{name: 'users'}" class="text-primary"> Add/Manage Users.</router-link>
        </div>
        <div class="card card-shadow">
          <draggable
            class="dragArea"
            :list="users"
            :clone="deepClone"
            :group="{ name: 'people', pull: 'clone', put: false }"
            @start="startDragUser"
          >
            <div v-for="user in users" v-bind:key="user.id" class="auditableContainer">
              <div class="user-card mb-1" style="cursor:pointer;">
                  <div class="">
                      <avatar class="float-right" :size="30" style="margin:2px;" :backgroundColor="user.color" :rounded="true" :username="user.name" />
                  </div>
                  <div class="user-name">{{user.name}}</div>
              </div>
            </div>
          </draggable>
        </div>
    </a-tab-pane>
  </a-tabs>
  </div>
  </div>
  </a-tab-pane> -->